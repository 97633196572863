import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import setSpacesInText from '../../functions/setSpacesInText';
import changePage from '../../functions/changePage';
import getPageLink from '../../functions/getPageLink';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkInit() {
        const { components, user } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;

            const alertLink = this.parent.current.querySelector('.indexCity__titleInfoAlert a');

            alertLink.onclick = (e) => {
                e.preventDefault();

                changePage({ href: getPageLink({ name: user ? 'profile' : 'login' }) });
            };
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    render() {
        const { components } = this.props;
        const game = components?.game;

        return (
            <>
                <div ref={this.parent} className={`indexCity ${components ? '_ready' : ''}`}>
                    <div className="indexCity__inner">
                        <div className="indexCity__title _TITLE _yellow">
                            <span
                                className="indexCity__titleInner"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(game?.header?.title)?.trim(),
                                }}
                            ></span>
                            <div className="indexCity__titleInfo">
                                i
                                <div className="indexCity__titleInfoAlertWrapper">
                                    <div
                                        className="indexCity__titleInfoAlert"
                                        dangerouslySetInnerHTML={{
                                            __html: game?.header?.description,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div className="indexCity__content">
                            <div className="indexCity__items">
                                {['icon1', 'icon2', 'icon3', 'icon4'].map((key) => (
                                    <div className="indexCity__item" key={key}>
                                        <div className="indexCity__itemHead">
                                            <img
                                                src={game?.[key]?.thumb}
                                                alt=""
                                                className="indexCity__itemImage"
                                            />
                                        </div>
                                        <div className="indexCity__itemTitle">
                                            {game?.[key]?.title}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <a
                                className="indexCity__button"
                                href={game?.button?.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="_purple">{game?.button?.title}</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
