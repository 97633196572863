import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../../functions/Slider';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibilityChange = this.visibilityChange.bind(this);

        this.parent = React.createRef();
    }

    intervalId;

    sliderInit() {
        const { device } = this.props;
        const slider = this.parent.current;

        const callback = () => {
            if (this.parent.current) {
                const currentItem = this.parent.current.querySelector(
                    '.indexHeader__item[data-current]',
                );

                this.parent.current.querySelectorAll('.indexHeader__item').forEach((item) => {
                    item.classList.remove('_active');
                });

                if (currentItem) {
                    const prevItem = currentItem.previousElementSibling;
                    const nextItem = currentItem.nextElementSibling;

                    if (prevItem) {
                        prevItem.classList.add('_active');
                    }

                    if (nextItem) {
                        nextItem.classList.add('_active');
                    }
                }
            }
        };

        this.slider = new Slider({
            slider,
            lay: slider.querySelector('.indexHeader__items'),
            items: slider.querySelectorAll('.indexHeader__item'),
            infinity: true,
            withResize: true,
            current: device === 'mobile' ? 0 : 1,
            calcStartCurrent: () => (this.props.device === 'mobile' ? 0 : 1),
            loop: 3_000,
            // type: 'mobile',
            callback,
        });
    }

    initGalery(force) {
        const { components } = this.props;

        if (components && (!this.isInit || force)) {
            this.isInit = true;

            clearInterval(this.intervalId);

            if (!force) {
                this.sliderInit();
            }
        }
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            this.slider.clearLoop();
        } else {
            this.slider.setLoop();
        }
    }

    componentDidMount() {
        this.initGalery();

        document.addEventListener('visibilityChange', this.visibilityChange);
    }

    componentDidUpdate() {
        this.initGalery();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);

        if (this.slider) {
            this.slider.destroy();
        }

        document.removeEventListener('visibilityChange', this.visibilityChange);
    }

    render() {
        const { user, components } = this.props;
        const header = components?.anounce;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexHeader ${components ? '_ready' : ''}`}
                    id="index"
                >
                    <div className="indexHeader__inner">
                        <div className="indexHeader__slogan _1"></div>
                        <div className="indexHeader__slogan _2">
                            <span>Выигрывай</span>
                            <span>Призы</span>
                        </div>
                        <div className="indexHeader__buttons">
                            <a
                                className="indexHeader__button"
                                href={header?.buttons?.button1?.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="_blue">
                                    {header?.buttons?.button1?.title || '–'}
                                </Button>
                            </a>
                            <Link
                                className="indexHeader__button"
                                pageName={user ? 'cheques' : 'login'}
                            >
                                <Button className="_purple">
                                    {header?.buttons?.button2?.title || '–'}
                                </Button>
                            </Link>
                        </div>
                        <div className="indexHeader__content">
                            <div className="indexHeader__items">
                                {header?.prizes?.map((item, key) => (
                                    <div className="indexHeader__item" key={key}>
                                        <div className="indexHeader__prize">
                                            <div className="indexHeader__prizeHead">
                                                <img
                                                    src={item.thumb}
                                                    alt=""
                                                    className="indexHeader__prizeImage"
                                                />
                                            </div>
                                            <div className="indexHeader__prizeTitle">
                                                {item.title}
                                            </div>
                                            <p className="indexHeader__prizeDescription">
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
