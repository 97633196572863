import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../functions/setSpacesInText';

import PrizeCard from '../../../components/PrizeCard.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import changePage from '../../../functions/changePage';

class ProfileContentPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkPrizes() {
        const { parent, privateContent, setUpdateKey } = this.props;

        if (privateContent && !this.isReady) {
            this.isReady = true;

            this.setState(
                {
                    prizes: privateContent.prizes,
                },
                () => {
                    setUpdateKey();

                    const prizes = parent.current.querySelector(
                        '.profileContent__pageContent._prizes',
                    );

                    if (prizes) {
                        this.setState(
                            {
                                prizesHeight: prizes.offsetHeight,
                            },
                            () => {
                                setUpdateKey();
                            },
                        );

                        setTimeout(() => {
                            this.setState(
                                {
                                    isCompleteReady: true,
                                },
                                () => {
                                    setUpdateKey();
                                },
                            );
                        }, 300);
                    }
                },
            );
        }
    }

    renderPrize(prize, key) {
        return (
            <div className="profileContent__prizesItem">
                <div className="profileContent__prize" key={key}>
                    <div className="profileContent__prizeHead">
                        <img src={prize.thumb} alt="" className="profileContent__prizeImage" />
                    </div>
                    <div
                        className="profileContent__prizeName"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(prize.title) }}
                    ></div>
                    <div
                        className={`profileContent__prizeStatus _${prize.status}`}
                        onClick={() => {
                            if (prize.status === 'DATA_NEEDED') {
                                changePage({ href: 'full-anket' });
                            }
                        }}
                    >
                        {prize.statusTitle}
                    </div>
                    {prize.error && <div className="profileContent__prizeError">{prize.error}</div>}
                    {prize.count && (
                        <div className="profileContent__prizeCount">
                            <div className="profileContent__prizeCountItem">{prize.count}</div>
                            шт
                        </div>
                    )}
                </div>
            </div>
        );
    }

    getRuffles() {
        const { components, privateContent } = this.props;
        const ruffles = [];

        if (components && privateContent) {
            const infoRuffles = components.prizes.raffles;

            Object.keys(infoRuffles).forEach((key) => {
                const privateInfo = privateContent[key];
                const info = infoRuffles[key];

                ruffles.push({
                    id: key,
                    info,
                    privateInfo,
                });
            });
        }

        return ruffles;
    }

    componentDidMount() {
        this.checkPrizes();
    }

    componentDidUpdate() {
        this.checkPrizes();
    }

    render() {
        const { prizes = [], prizesHeight, isCompleteReady } = this.state;
        const { components, privateContent } = this.props;
        const ruffles = this.getRuffles();
        const merch = components?.prizes?.merch;
        const guaranteed = components?.prizes?.guaranteed;

        return (
            <>
                <div className="profileContent__pageHead">
                    <div className="profileContent__pageTitle">ПОЛУЧАЙ И ВЫИГРЫВАЙ ПРИЗЫ</div>
                </div>
                <div className={`profileContent__pageContent _top ${components ? '_ready' : ''}`}>
                    <div className="profileContent__marks">
                        {ruffles.map((item, key) => (
                            <div className="profileContent__mark" key={key}>
                                <PrizeCard {...item} />
                            </div>
                        ))}
                        {merch && (
                            <div className="profileContent__mark">
                                <PrizeCard merch={merch} />
                            </div>
                        )}
                        {guaranteed && (
                            <div className="profileContent__mark">
                                <PrizeCard
                                    guaranteed={{
                                        ...guaranteed.info,
                                        guaranteed: privateContent?.guaranteed,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {(!components || prizes.length > 0) && (
                    <>
                        <div className="profileContent__pageHead">
                            <div className="profileContent__pageTitle">мои призы</div>
                        </div>
                        <div className={`profileContent__pageContent _prizes`}>
                            <div
                                className="profileContent__prizesWrapper"
                                style={
                                    isCompleteReady
                                        ? null
                                        : prizesHeight
                                        ? { height: `${prizesHeight}px` }
                                        : { height: '30rem' }
                                }
                            >
                                <Animate
                                    className="profileContent__pageLoader"
                                    isShow={!isCompleteReady}
                                >
                                    <div className="profileContent__pageLoaderItem">
                                        <Loader />
                                    </div>
                                </Animate>
                                <div
                                    className={`profileContent__prizes ${
                                        isCompleteReady ? '_ready' : ''
                                    }`}
                                >
                                    {prizes.map(this.renderPrize)}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentPrizes);

ProfileContentPrizes.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
};
