import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Footer from '../../components/Footer.jsx';
import Button from '../../components/Button.jsx';

import Slider from '../../functions/Slider';
import setSpacesInText from '../../functions/setSpacesInText';
import Arrow from '../../components/Arrow.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibilityChange = this.visibilityChange.bind(this);

        this.parent = React.createRef();
    }

    sliderInit() {
        const slider = this.parent.current;

        this.slider = new Slider({
            slider,
            lay: slider.querySelector('.indexMainPrizes__items'),
            items: slider.querySelectorAll('.indexMainPrizes__item'),
            infinity: true,
            withResize: true,
            current: 0,
            loop: 3_000,
            type: 'mobile',
            pagenation: {
                parent: slider.querySelector('.indexMainPrizes__pagination'),
                dot: 'indexMainPrizes__paginationItem',
            },
            buttons: {
                prev: slider.querySelector('.indexMainPrizes__btn._prev'),
                next: slider.querySelector('.indexMainPrizes__btn._next'),
            },
        });
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            if (this.slider) {
                this.slider.clearLoop();
            }
        } else if (this.slider) {
            this.slider.setLoop();
        }
    }

    checkInit() {
        const { components } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;

            this.sliderInit();
        }
    }

    componentDidMount() {
        document.addEventListener('visibilityChange', this.visibilityChange);

        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilityChange', this.visibilityChange);

        if (this.slider) {
            this.slider.destroy();
        }
    }

    render() {
        const { device, components } = this.props;
        const monthly = components?.monthly;
        const app = components?.app;

        return (
            <>
                <div ref={this.parent} className={`indexMainPrizes ${components ? '_ready' : ''}`}>
                    <div className="indexMainPrizes__inner">
                        <div className="indexMainPrizes__head">
                            <div className="indexMainPrizes__headTitle _TITLE">
                                {monthly?.title?.title}
                            </div>
                            <div
                                className="indexMainPrizes__headDescription _TEXTBLOCK"
                                dangerouslySetInnerHTML={{ __html: monthly?.title?.description }}
                            ></div>
                        </div>
                        <div className="indexMainPrizes__content">
                            <Arrow
                                className="indexMainPrizes__btn _prev"
                                name="prev"
                                white={true}
                            ></Arrow>
                            <Arrow
                                className="indexMainPrizes__btn _next"
                                name="next"
                                white={true}
                            ></Arrow>
                            <div className="indexMainPrizes__items">
                                {monthly?.prizes?.thumbsExtra?.map((item, key) => (
                                    <div className="indexMainPrizes__item" key={key}>
                                        <img
                                            src={item[0]}
                                            alt=""
                                            className="indexMainPrizes__itemImage"
                                        />
                                        <p className="indexMainPrizes__itemName">{item[1]}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="indexMainPrizes__pagination"></div>
                        </div>
                        <div className="indexMainPrizes__head">
                            <div className="indexMainPrizes__headTitle _TITLE">
                                {app?.title?.title}
                            </div>
                            <p
                                className="indexMainPrizes__headDescription"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(app?.title?.description),
                                }}
                            ></p>
                        </div>
                        <div className="indexMainPrizes__app">
                            <div className="indexMainPrizes__appPhone">
                                <img
                                    src={app?.application?.thumb}
                                    alt=""
                                    className="indexMainPrizes__appPhoneImage"
                                />
                            </div>

                            <div className="indexMainPrizes__appBox">
                                <div className="indexMainPrizes__appQr">
                                    <img
                                        src={app?.qr?.thumb}
                                        alt=""
                                        className="indexMainPrizes__appQrImage"
                                    />
                                </div>
                                <div className="indexMainPrizes__appContent">
                                    {device === 'mobile' ? (
                                        <>скачай приложение</>
                                    ) : (
                                        <>Отсканируй QR-код для&nbsp;загрузки приложения</>
                                    )}
                                </div>
                            </div>
                        </div>
                        {device === 'mobile' && (
                            <>
                                <a
                                    className="indexMainPrizes__button"
                                    href={app?.[0]?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button className="_purple">{app?.[0]?.title}</Button>
                                </a>
                            </>
                        )}
                        <div className="indexMainPrizes__footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
