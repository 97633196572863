import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import setSpacesInText from '../../functions/setSpacesInText';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components } = this.props;
        const howto = components?.howto;

        return (
            <>
                <div ref={this.parent} className={`indexPrizes ${components ? '_ready' : ''}`} id="rules">
                    <div className="indexPrizes__inner">
                        <div
                            className="indexPrizes__title _TITLE _mediumSize _yellow"
                            dangerouslySetInnerHTML={{ __html: howto?.period?.title }}
                        ></div>
                        <div className="indexPrizes__items">
                            <div className="indexPrizes__item">
                                <div className="indexPrizes__itemHead">
                                    <img
                                        src={howto?.step1?.thumb}
                                        alt=""
                                        className="indexPrizes__itemImage"
                                    />
                                </div>
                                <div className="indexPrizes__itemContent">
                                    <div className="indexPrizes__itemTitle">
                                        {howto?.step1?.title}
                                    </div>
                                    <p
                                        className="indexPrizes__itemDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(howto?.step1?.description),
                                        }}
                                    ></p>
                                </div>
                            </div>
                            <div className="indexPrizes__item">
                                <div className="indexPrizes__itemHead">
                                    <img
                                        src={howto?.step2?.thumb}
                                        alt=""
                                        className="indexPrizes__itemImage"
                                    />
                                </div>
                                <div className="indexPrizes__itemContent">
                                    <div className="indexPrizes__itemTitle">
                                        {howto?.step2?.title}
                                    </div>
                                    <div
                                        className="indexPrizes__itemDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(howto?.step2?.description),
                                        }}
                                    ></div>
                                </div>
                            </div>
                            <div className="indexPrizes__item">
                                <div className="indexPrizes__itemHead">
                                    <img
                                        src={howto?.step3?.thumb}
                                        alt=""
                                        className="indexPrizes__itemImage"
                                    />
                                </div>
                                <div className="indexPrizes__itemContent">
                                    <div
                                        className="indexPrizes__itemTitle"
                                        dangerouslySetInnerHTML={{ __html: howto?.step3?.title }}
                                    ></div>
                                    <p
                                        className="indexPrizes__itemDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(howto?.step3?.description),
                                        }}
                                    ></p>
                                </div>
                            </div>
                            <div className="indexPrizes__item">
                                <div className="indexPrizes__itemHead">
                                    <img
                                        src={howto?.step4?.thumb}
                                        alt=""
                                        className="indexPrizes__itemImage"
                                    />
                                </div>
                                <div className="indexPrizes__itemContent">
                                    <div
                                        className="indexPrizes__itemTitle"
                                        dangerouslySetInnerHTML={{ __html: howto?.step4?.title }}
                                    ></div>
                                    <p
                                        className="indexPrizes__itemDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(howto?.step4?.description),
                                        }}
                                    ></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
