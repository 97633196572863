import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../../functions/Slider';
import setSpacesInText from '../../functions/setSpacesInText';

import Arrow from '../../components/Arrow.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibilityChange = this.visibilityChange.bind(this);

        this.parent = React.createRef();
    }

    sliderInit() {
        const slider = this.parent.current;

        this.slider = new Slider({
            slider,
            lay: slider.querySelector('.indexChildPrizes__items'),
            items: slider.querySelectorAll('.indexChildPrizes__item'),
            infinity: true,
            withResize: true,
            loop: 3_000,
            current: 0,
            type: 'mobile',
            pagenation: {
                parent: slider.querySelector('.indexChildPrizes__pagination'),
                dot: 'indexChildPrizes__paginationItem',
            },
            buttons: {
                prev: slider.querySelector('.indexChildPrizes__btn._prev'),
                next: slider.querySelector('.indexChildPrizes__btn._next'),
            },
        });
    }

    checkInit() {
        const { components } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;

            this.sliderInit();
        }
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            if (this.slider) {
                this.slider.clearLoop();
            }
        } else if (this.slider) {
            this.slider.setLoop();
        }
    }

    componentDidMount() {
        this.checkInit();

        document.addEventListener('visibilityChange', this.visibilityChange);
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilityChange', this.visibilityChange);

        if (this.slider) {
            this.slider.destroy();
        }
    }

    render() {
        const { components } = this.props;
        const merch = components?.merch;

        return (
            <>
                <div ref={this.parent} className={`indexChildPrizes ${components ? '_ready' : ''}`}>
                    <div className="indexChildPrizes__inner">
                        <div className="indexChildPrizes__head">
                            <div
                                className="indexChildPrizes__headTitle _TITLE _yellow"
                                dangerouslySetInnerHTML={{
                                    __html: merch?.title?.title?.title || merch?.title?.title,
                                }}
                            ></div>
                            <div
                                className="indexChildPrizes__headDescription _TEXTBLOCK"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        merch?.title?.title?.description ||
                                            merch?.title?.description,
                                    ),
                                }}
                            ></div>
                        </div>
                        <div className="indexChildPrizes__content">
                            <Arrow
                                className="indexChildPrizes__btn _prev"
                                name="prev"
                                white={true}
                            ></Arrow>
                            <Arrow
                                className="indexChildPrizes__btn _next"
                                name="next"
                                white={true}
                            ></Arrow>
                            <div className="indexChildPrizes__items">
                                {merch?.prizes?.map((item, key) => (
                                    <div className="indexChildPrizes__item" key={key}>
                                        <div className="indexChildPrizes__itemHead">
                                            <div className="indexChildPrizes__itemHeadBox">
                                                <img
                                                    src={item.thumb}
                                                    alt=""
                                                    className="indexChildPrizes__itemImage"
                                                />
                                            </div>
                                        </div>
                                        <div className="indexChildPrizes__itemTitle">
                                            {item.title}
                                        </div>
                                        <div
                                            className="indexChildPrizes__itemDescription"
                                            dangerouslySetInnerHTML={{ __html: item.description }}
                                        ></div>
                                        {item.tips && (
                                            <div className="indexChildPrizes__itemSupport">
                                                {item.tips}
                                                <div className="indexChildPrizes__itemSupportInfo">
                                                    i
                                                    <div
                                                        className="indexChildPrizes__itemSupportInfoAlert"
                                                        dangerouslySetInnerHTML={{
                                                            __html: setSpacesInText(item.tipsFull),
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="indexChildPrizes__pagination"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
