import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../../functions/Slider';
import setSpacesInText from '../../functions/setSpacesInText';
import Arrow from '../../components/Arrow.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibilityChange = this.visibilityChange.bind(this);

        this.parent = React.createRef();
    }

    sliders = {};

    sliderInit() {
        // const slider = this.parent.current;

        this.parent.current
            .querySelectorAll('.indexWeeksPrizes__content')
            .forEach((content, key) => {
                this.sliders[key] = new Slider({
                    slider: content,
                    lay: content.querySelector('.indexWeeksPrizes__items'),
                    items: content.querySelectorAll('.indexWeeksPrizes__item'),
                    infinity: true,
                    withResize: true,
                    loop: 3_000,
                    current: 0,
                    type: key === 1 ? 'mobile' : undefined,
                    pagenation: {
                        parent: content.querySelector('.indexWeeksPrizes__pagination'),
                        dot: 'indexWeeksPrizes__paginationItem',
                    },
                    buttons: {
                        prev: content.querySelector('.indexWeeksPrizes__btn._prev'),
                        next: content.querySelector('.indexWeeksPrizes__btn._next'),
                    },
                });
            });
    }

    checkInit() {
        const { components } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;

            this.sliderInit();
        }
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            Object.keys(this.sliders).forEach((key) => {
                this.sliders[key].clearLoop();
            });
        } else {
            Object.keys(this.sliders).forEach((key) => {
                this.sliders[key].setLoop();
            });
        }
    }

    componentDidMount() {
        this.checkInit();

        document.addEventListener('visibilityChange', this.visibilityChange);
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        Object.keys(this.sliders).forEach((key) => {
            this.sliders[key].destroy();
        });

        document.removeEventListener('visibilityChange', this.visibilityChange);
    }

    render() {
        const { components } = this.props;
        const weekly = components?.weekly;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexWeeksPrizes ${components ? '_ready' : ''}`}
                    id="prizes"
                >
                    <div className="indexWeeksPrizes__inner">
                        <div className="indexWeeksPrizes__head">
                            <div className="indexWeeksPrizes__headTitle _TITLE">
                                {weekly?.title?.title}
                            </div>
                            <p
                                className="indexWeeksPrizes__headDescription"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(weekly?.title?.description),
                                }}
                            ></p>
                            <div
                                className="indexWeeksPrizes__headInfo _TEXTBLOCK"
                                dangerouslySetInnerHTML={{
                                    __html: weekly?.certificates?.description,
                                }}
                            ></div>
                        </div>
                        <div className="indexWeeksPrizes__content _top">
                            <Arrow
                                className="indexWeeksPrizes__btn _prev"
                                name="prev"
                                white={true}
                            ></Arrow>
                            <Arrow
                                className="indexWeeksPrizes__btn _next"
                                name="next"
                                white={true}
                            ></Arrow>
                            <div className="indexWeeksPrizes__items _top">
                                {weekly?.certificates?.thumbsExtra?.map((item, key) => (
                                    <div
                                        className={`indexWeeksPrizes__item _${(key % 4) + 1}`}
                                        key={item}
                                    >
                                        <div className="indexWeeksPrizes__itemHead">
                                            <img
                                                src={item[0]}
                                                alt=""
                                                className="indexWeeksPrizes__itemImage"
                                            />
                                        </div>
                                        <div className="indexWeeksPrizes__itemDescription">
                                            {item[1]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="indexWeeksPrizes__pagination"></div>
                        </div>
                        <div className="indexWeeksPrizes__head">
                            <div
                                className="indexWeeksPrizes__headInfo _TEXTBLOCK"
                                dangerouslySetInnerHTML={{ __html: weekly?.prizes?.description }}
                            ></div>
                        </div>
                        <div className="indexWeeksPrizes__content _bottom">
                            <Arrow
                                className="indexWeeksPrizes__btn _prev"
                                name="prev"
                                white={true}
                            ></Arrow>
                            <Arrow
                                className="indexWeeksPrizes__btn _next"
                                name="next"
                                white={true}
                            ></Arrow>
                            <div className="indexWeeksPrizes__items _bottom">
                                {weekly?.prizes?.thumbsExtra?.map((item) => (
                                    <div className="indexWeeksPrizes__item" key={item}>
                                        <div className="indexWeeksPrizes__itemHead">
                                            <img
                                                src={item[0]}
                                                alt=""
                                                className="indexWeeksPrizes__itemImage"
                                            />
                                        </div>
                                        <div
                                            className="indexWeeksPrizes__itemDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(item[1]),
                                            }}
                                        ></div>
                                    </div>
                                ))}
                            </div>
                            <div className="indexWeeksPrizes__pagination"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
