import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from '../components/Popup.jsx';
import Field from '../components/Field.jsx';
import Link from '../components/Link.jsx';
import Button from '../components/Button.jsx';

import { dispatcher } from '../redux/redux';
import saveJWT from '../functions/saveJWT';
import checkAuth from '../functions/checkAuth';
import changePage from '../functions/changePage';
import getHeaders from '../functions/getHeaders';

class Login extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.login = this.login.bind(this);

        this.parent = React.createRef();
    }

    isPrivate = false;

    socials = [
        {
            key: 'vk',
            icon: 'vk-icon.svg',
            content: 'VK',
        },
        {
            key: 'mail',
            icon: 'mail-icon.svg',
            content: 'Mail.ru',
        },
        {
            key: 'ya',
            icon: 'ya-icon.svg',
            content: 'Яндекс',
        },
        {
            key: 'ok',
            icon: 'ok-icon.svg',
            content: 'Ok',
        },
    ];

    login() {
        const { fields } = this.state;
        const { galleryId } = this.props;

        this.handlerLoading('login').then(() => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/Login`,
                    {
                        login: fields?.login,
                        password: fields?.password,
                    },
                    {
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        const { result } = res.data;

                        if (result === 'OK') {
                            const { JWT } = res.data;

                            dispatcher({ type: 'isAuthProccessed', data: true }).then(() => {
                                saveJWT(JWT);

                                checkAuth().then((user) => {
                                    this.handlerLoading(null);

                                    if (user) {
                                        localStorage.removeItem('regEmail');

                                        if (galleryId) {
                                            changePage({
                                                href: `gallery/${galleryId}`,
                                            });

                                            dispatcher({ type: 'galleryId', data: null });
                                        } else {
                                            changePage({
                                                href:
                                                    user.status === 'ANKET_REQUIRED'
                                                        ? 'anket'
                                                        : 'profile',
                                            });
                                        }

                                        setTimeout(() => {
                                            dispatcher({
                                                type: 'isAuthProccessed',
                                                data: false,
                                            });
                                        }, 500);
                                    } else {
                                        this.setState({ loginError: 'Ошибка авторизации' });

                                        dispatcher({
                                            type: 'isAuthProccessed',
                                            data: false,
                                        });
                                    }
                                });
                            });
                        }
                    },
                    (err) => {
                        this.handlerLoading(null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ loginError: errorText });
                        } catch (error) {
                            this.setState({ loginError: 'Ошибка сервера' });
                        }
                    },
                );
        });
    }

    componentDidMount() {
        const { afterLogin, afterPassword } = this.props;

        super.componentDidMount();

        if (afterLogin) {
            this.setState(
                {
                    afterLogin,
                    fields: {
                        phone: afterLogin,
                        ...(afterPassword ? { password: afterPassword } : {}),
                    },
                },
                () => {
                    dispatcher({ type: 'afterLogin', data: null });
                    dispatcher({ type: 'afterPassword', data: null });

                    this.login();
                },
            );
        }
    }

    render() {
        const { fields, loginError, loadingKey, afterLogin } = this.state;
        const { mainContent } = this.props;
        const socAuthUrls = window.socAuthUrls || mainContent?.socAuthUrls;

        return (
            <>
                <Popup>
                    <div className="popup__head">
                        <div className="popup__headTitle">Вход в Личный кабинет</div>
                        <p className="popup__headDescription">Необходима авторизация</p>
                    </div>
                    <div className="popup__content">
                        <div className="popup__fields">
                            <div className="popup__field">
                                <Field
                                    className="_medium"
                                    support="Адрес электронной почты (логин)"
                                    name="login"
                                    value={fields?.login}
                                    onChange={this.handlerField}
                                />
                            </div>
                            <div className="popup__field">
                                <Field
                                    className="_medium"
                                    support="Пароль"
                                    name="password"
                                    value={fields?.password}
                                    onChange={this.handlerField}
                                >
                                    <Link
                                        href="registration"
                                        className="popup__fieldLink"
                                        // prevActions={() => {
                                        //     dispatcher({ type: 'saveLogin', data: fields?.phone });
                                        // }}
                                    >
                                        Забыли пароль?
                                    </Link>
                                </Field>
                            </div>
                        </div>
                        <div className="popup__socials">
                            <p className="popup__socialsSupport">авторизоваться через</p>
                            <div className="popup__socialsItems">
                                {this.socials.map((social) => {
                                    const { key, icon, content } = social;
                                    let href;

                                    if (key === 'vk') {
                                        href = socAuthUrls?.VK;
                                    }

                                    if (key === 'mail') {
                                        href = socAuthUrls?.MailRU;
                                    }

                                    if (key === 'ya') {
                                        href = socAuthUrls?.Yandex;
                                    }

                                    if (key === 'ok') {
                                        href = socAuthUrls?.OK;
                                    }

                                    return (
                                        <div
                                            className="popup__socialsItem"
                                            key={key}
                                            onClick={() => {
                                                window.open(href, '_blank', 'width=600,height=425');
                                            }}
                                        >
                                            <div className="popup__socialsItemInner">
                                                <img
                                                    src={require(`../media/${icon}`)}
                                                    alt=""
                                                    className="popup__socialsItemIcon"
                                                />
                                                {content}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={`popup__foot ${afterLogin ? '_afterLogin' : ''}`}>
                        {afterLogin && false && (
                            <div className="popup__regInfo">Вам отправлено сообщение с паролем</div>
                        )}
                        {this.renderError({ error: loginError })}
                        <div className="popup__buttons">
                            <Link
                                href="registration"
                                className="popup__button"
                                prevActions={() => {
                                    dispatcher({ type: 'saveLogin', data: fields?.phone });
                                }}
                            >
                                <Button className="_emptyPurple _mediumSize">РЕГИСТРАЦИЯ</Button>
                            </Link>
                            <div className="popup__button">
                                <Button
                                    className="_purple _mediumSize"
                                    onClick={this.login}
                                    loader={loadingKey === 'login'}
                                >
                                    ВОЙТИ
                                </Button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        mainContent: state.mainContent,
        galleryId: state.galleryId,
        isAuthProccessed: state.isAuthProccessed,
        afterLogin: state.afterLogin,
        afterPassword: state.afterPassword,
    };
}

export default connect(mapStateToProps)(Login);

Login.propTypes = {
    device: PropTypes.string,
    mainContent: PropTypes.object,
    galleryId: PropTypes.string,
    isAuthProccessed: PropTypes.bool,
    afterLogin: PropTypes.bool,
};
