import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../scss/main.scss';

import { dispatcher } from '../redux/redux';

import saveJWT from '../functions/saveJWT';
import { getCookie, setCookie } from '../functions/handlerCookies';
import checkAuth from '../functions/checkAuth';
import getCurrentPage from '../functions/getCurrentPage';
import changePage from '../functions/changePage';

import Pages from '../components/Pages.jsx';
import TopBar from '../components/TopBar.jsx';
import MobileMenu from '../components/MobileMenu.jsx';
import Cookies from '../components/Cookies.jsx';
import CuponPopup from '../components/CuponPopup.jsx';

import Animate from '../components/Animate.jsx';
import WaitHeader from '../components/WaitHeader.jsx';

import Index from './Index.jsx';
import About from './About.jsx';
import Winners from './Winners.jsx';
import Faq from './Faq.jsx';
import Login from './Login.jsx';
import Registration from './Registration.jsx';
import Profile from './Profile.jsx';
import Anket from './Anket.jsx';
import Cheques from './Cheques.jsx';
import MarksPopup from '../components/MarksPopup.jsx';
import ReferalPopup from '../components/ReferalPopup.jsx';
import MerchPopup from '../components/MerchPopup.jsx';
import GameUserPopup from '../components/GameUserPopup.jsx';
import GameEnergyPopup from '../components/GameEnergyPopup.jsx';
import RufflePopup from '../components/RufflePopup.jsx';
import EmailPopup from '../components/EmailPopup.jsx';
import Game from './Game.jsx';
import FullAnket from './FullAnket.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerAncor = this.handlerAncor.bind(this);
        this.setScroll = this.setScroll.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    // waitMode = true;

    pages = {
        index: {
            render() {
                return (
                    <>
                        <Index setScroll={this.setScroll} />
                    </>
                );
            },
        },
        about: {
            render() {
                return (
                    <>
                        <About setScroll={this.setScroll} />
                    </>
                );
            },
        },
        winners: {
            render() {
                return (
                    <>
                        <Winners setScroll={this.setScroll} />
                    </>
                );
            },
        },
        faq: {
            render() {
                return (
                    <>
                        <Faq setScroll={this.setScroll} />
                    </>
                );
            },
        },
        login: {
            render() {
                return (
                    <>
                        <Login setScroll={this.setScroll} />
                    </>
                );
            },
        },
        registration: {
            render() {
                return (
                    <>
                        <Registration setScroll={this.setScroll} />
                    </>
                );
            },
        },
        profile: {
            render() {
                return (
                    <>
                        <Profile setScroll={this.setScroll} />
                    </>
                );
            },
        },
        anket: {
            render() {
                return (
                    <>
                        <Anket setScroll={this.setScroll} />
                    </>
                );
            },
        },
        cheques: {
            render() {
                return (
                    <>
                        <Cheques />
                    </>
                );
            },
        },
        game: {
            render() {
                return (
                    <>
                        <Game />
                    </>
                );
            },
        },
        fullAnket: {
            render() {
                return (
                    <>
                        <FullAnket />
                    </>
                );
            },
        },
        anketForm: {
            render() {
                return (
                    <>
                        <FullAnket isShort={true} />
                    </>
                );
            },
        },
    };

    visibilityDocChange() {
        if (!document.hidden) {
            checkAuth();
        }

        document.dispatchEvent(
            new CustomEvent('visibilityChange', { detail: { active: !document.hidden } }),
        );
    }

    handlerAncor({ detail: { name, changeIsHard } }) {
        const { storePages } = this.props;
        const currentPage = getCurrentPage({ pages: storePages, filter: (page) => !page.level });

        if (currentPage !== 'index') {
            dispatcher({ type: 'indexAncor', data: name });
            changePage({ href: '', changeIsHard });
            dispatcher({ type: 'mobileMenuShow', data: false });
        } else {
            document.dispatchEvent(new CustomEvent('indexScroll', { detail: { name } }));
        }
    }

    setScroll(scrollTop) {
        const { device, storePages } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => !page.isPopup && !page.level,
        });
        const stopValue = device === 'mobile' || currentPage !== 'index' ? 1 : 1;

        if (scrollTop >= stopValue && !this.state.topBarActive) {
            this.setState({ topBarActive: true });
        }

        if (scrollTop < stopValue && this.state.topBarActive) {
            this.setState({ topBarActive: false });
        }
    }

    componentDidMount() {
        document.addEventListener('indexAncor', this.handlerAncor);
        document.addEventListener('visibilitychange', this.visibilityDocChange);

        const rootJWT = document.querySelector('#root').getAttribute('data-jwt');

        if (rootJWT) {
            setCookie(process.env.REACT_APP_HASH, rootJWT);
        }

        saveJWT(getCookie(process.env.REACT_APP_HASH));

        checkAuth();

        window.getJWT = () => getCookie(process.env.REACT_APP_HASH);
        window.saveJWT = saveJWT;
        window.apiUrl = process.env.REACT_APP_API;
    }

    render() {
        const { topBarActive } = this.state;
        const {
            windowIsReady,
            device,
            storePages,
            cuponPopup,
            windowHeight,
            marksPopup,
            referalPopup,
            orderPopup,
            merchPopup,
            gameUserPopup,
            gameEnergyPopup,
            rufflePopup,
            emailPopup,
        } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => !page.isPopup && !page.level,
        });
        const currentPopupPage = getCurrentPage({
            pages: storePages,
            filter: (page) => page.isPopup,
        });

        return (
            <>
                <div
                    ref={this.parent}
                    className={`body__contentInner ${this.waitMode ? '_waitMode' : ''}`}
                >
                    {this.waitMode ? (
                        <>
                            <div className="body__pages">
                                <div
                                    className="body__page _show"
                                    style={{ height: `${windowHeight}px` }}
                                >
                                    <WaitHeader />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {device === 'mobile' && (
                                <>
                                    <MobileMenu />
                                </>
                            )}
                            {!['game', 'anketForm'].includes(currentPage) && (
                                <TopBar isActive={topBarActive} isInner={currentPage !== 'index'} />
                            )}
                            <Cookies />
                            <Animate
                                className="body__popupBack"
                                isShow={
                                    !!currentPopupPage ||
                                    cuponPopup?.isShow ||
                                    marksPopup?.isShow ||
                                    referalPopup?.isShow ||
                                    orderPopup?.isShow ||
                                    merchPopup?.isShow ||
                                    gameUserPopup?.isShow ||
                                    gameEnergyPopup?.isShow ||
                                    rufflePopup?.isShow ||
                                    emailPopup?.isShow
                                }
                            />

                            <Animate className="body__popup" isShow={cuponPopup?.isShow}>
                                <CuponPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={marksPopup?.isShow}>
                                <MarksPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={referalPopup?.isShow}>
                                <ReferalPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={merchPopup?.isShow}>
                                <MerchPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={gameUserPopup?.isShow}>
                                <GameUserPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={gameEnergyPopup?.isShow}>
                                <GameEnergyPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={rufflePopup?.isShow}>
                                <RufflePopup />
                            </Animate>
                            <Animate className="body__popup" isShow={emailPopup?.isShow}>
                                <EmailPopup />
                            </Animate>

                            {windowIsReady && (
                                <Pages
                                    className="body__pages"
                                    classNamePage="body__page"
                                    filter={(page) => !page.level}
                                    pages={this.pages}
                                    context={this}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        storePages: state.pages,
        windowIsReady: state.windowIsReady,
        user: state.user,
        cuponPopup: state.cuponPopup,
        windowHeight: state.windowHeight,
        marksPopup: state.marksPopup,
        referalPopup: state.referalPopup,
        orderPopup: state.orderPopup,
        merchPopup: state.merchPopup,
        gameUserPopup: state.gameUserPopup,
        gameEnergyPopup: state.gameEnergyPopup,
        rufflePopup: state.rufflePopup,
        emailPopup: state.emailPopup,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    device: PropTypes.string,
    windowIsReady: PropTypes.bool,
    storePages: PropTypes.object,
    user: PropTypes.object,
    cuponPopup: PropTypes.object,
    windowHeight: PropTypes.number,
    marksPopup: PropTypes.object,
    referalPopup: PropTypes.object,
    orderPopup: PropTypes.object,
    merchPopup: PropTypes.object,
    gameUserPopup: PropTypes.object,
    gameEnergyPopup: PropTypes.object,
    rufflePopup: PropTypes.object,
    emailPopup: PropTypes.object,
};
